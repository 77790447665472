<template>
  <b-card no-body class="card card-custom card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">
        {{ title }}
      </h3>
    </div>
    <!--end::Header-->
    <div class="card-body pt-7">
      <div class="timeline timeline-5">
        <div class="timeline-items">
          <!--begin::Item-->
          <div
            class="timeline-item text-left mb-3"
            v-for="session in formatedSessions"
            :key="session.id"
          >
            <!--begin::Icon-->
            <div
              :class="`timeline-media bg-light-${getClassBySession(session)}`"
            >
              <span
                :class="
                  `svg-icon svg-icon-2x svg-icon-${getClassBySession(session)}`
                "
              >
                <simple-svg :src="require(`@/assets/images/icons/fish.svg`)" />
              </span>
            </div>
            <!--end::Icon-->

            <!--begin::Info-->
            <div
              :class="
                `timeline-desc timeline-desc-light-${getClassBySession(
                  session
                )}`
              "
            >
              <span
                class="font-weight-bolder d-flex align-items-center justify-content-between mb-1"
              >
                <span
                  :class="`text-${getClassBySession(session)}`"
                  v-html="shortenName(session.name)"
                />
                <b-button
                  class="btn-icon mb-2"
                  size="sm"
                  :variant="
                    selected &&
                    parseInt(selected) === parseInt(session.surveyEntityId)
                      ? `${getClassBySession(session)}`
                      : `outline-${getClassBySession(session)}`
                  "
                  @click="$emit('select', session)"
                >
                  <i class="fas fa-chevron-right"></i>
                </b-button>
              </span>
              <div
                class="font-weight-bolder d-flex align-items-top justify-content-between pb-1"
              >
                <span
                  :class="
                    `label label-lg label-outline-${getClassBySession(
                      session
                    )} font-weight-bolder label-inline`
                  "
                >
                  {{ session.category }}
                </span>
                <p
                  class="font-weight-normal text-muted text-dark-50"
                  v-html="session.calendar"
                />
              </div>
            </div>
            <!--end::Info-->
          </div>
          <!--end::Item-->
        </div>
      </div>
    </div>
    <div class="card-footer border-top-0 pt-0" v-if="hasPager">
      <div class="d-flex justify-content-around">
        <div class="d-flex flex-column">
          <b-button
            pill
            :variant="canGoUp ? 'light-primary' : 'disabled'"
            :disabled="!canGoUp"
            class="btn-icon"
            @click="up"
          >
            <i class="fa fa-chevron-up"></i>
          </b-button>
        </div>
        <div class="d-flex flex-column">
          <b-button
            pill
            :variant="canGoDown ? 'light-primary' : 'disabled'"
            :disabled="!canGoDown"
            class="btn-icon"
            @click="down"
          >
            <i class="fa fa-chevron-down"></i>
          </b-button>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import { mapGetters } from "vuex";

import { SimpleSVG } from "vue-simple-svg";

export default {
  name: "PhishingTimeline",
  props: {
    title: {
      type: String,
      required: true
    },
    sessions: {
      type: Array,
      required: true
    },
    limit: {
      type: Number,
      default: 6,
      required: false
    },
    controls: {
      type: Boolean,
      default: true,
      required: false
    },
    selected: {
      required: false,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false,
      required: false
    },
    shorten: {
      type: Boolean,
      required: false
    }
  },
  components: {
    "simple-svg": SimpleSVG
  },
  data() {
    return {
      page: 0
    };
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"]),
    hasPager() {
      return this.sessions.length > this.limit;
    },
    formatedSessions() {
      var result = [];
      _.forEach(
        _.slice(
          this.sessions,
          this.limit * this.page,
          this.limit * (this.page + 1)
        ),
        o => {
          var session = o;

          session.id = o.surveyEntityId;
          session.category = o.campaignCategory;
          session.calendar = moment().to(o.userStartDate);
          session.name = this.shorten
            ? this.shortenName(o.campaign)
            : o.campaign;
          result.push(session);
        }
      );
      return result;
    },
    canGoUp() {
      return this.page > 0 && this.sessions.length > 0;
    },
    canGoDown() {
      return (
        this.sessions.length > 0 &&
        (this.page + 1) * this.limit < this.sessions.length
      );
    }
  },
  methods: {
    shortenName(name) {
      return name.length > 25 ? name.substring(0, 25) + ".." : name;
    },
    up() {
      if (this.canGoUp) this.page--;
    },
    down() {
      if (this.canGoDown) this.page++;
    },
    getClassBySession(session) {
      return session.userEndDate == this.getConstant("NULL_DATE")
        ? "warning"
        : moment(session.userEndDate).isSameOrAfter(moment())
        ? "warning"
        : "success";
    }
  }
};
</script>

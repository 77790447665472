<template>
  <b-card class="card-custom card-stretch gutter-b mb-6" no-body id="user-tabs">
    <b-tabs
      v-model="tabValue"
      card
      pills
      lazy
      nav-class="nav-light-success nav-bold col"
      nav-wrapper-class="card-toolbar row pt-2 pb-4 d-flex align-items-center"
      align="left"
      content-class="card-body text-left"
      @activate-tab="
        ted = {};
        youtube = {};
        vimeo = {};
        iframe = {};
        html = {};
        ownVideo = {};
      "
    >
      <template v-slot:empty>
        <div
          class="text-center text-muted"
          v-html="
            $t('generic.empty', {
              module: title || (object ? object.title : '')
            })
          "
        ></div>
      </template>
      <template v-slot:tabs-start v-if="title">
        <div class="card-title">
          <h3 class="card-label" v-html="title" />
        </div>
      </template>
      <template v-slot:tabs-end>
        <b-dropdown
          toggle-tag="a"
          size="sm"
          right
          :class="tabValue >= chunkAt ? 'nav-item active' : 'nav-item'"
          :menu-class="tabValue >= chunkAt ? 'nav-item active' : ''"
          :toggle-class="tabValue >= chunkAt ? 'nav-link active' : 'nav-link'"
          role="button"
          variant="clean"
          v-if="invisibleTabs.length > 0"
        >
          <template v-slot:button-content>
            <span class="nav-icon">
              <i class="flaticon2-gear"></i>
            </span>
            <span class="nav-text">{{ $t("profile.tabs.more") }}</span>
          </template>
          <b-dropdown-item
            v-for="(invisible, invisibleIndex) in invisibleTabs"
            :key="invisible.key"
            @click="changeTab(chunkAt + invisibleIndex)"
            :active="tabValue === chunkAt + invisibleIndex"
          >
            {{
              getTab(
                invisible.actionTypeId,
                invisible.dimensionGroupId,
                invisible
              ).title
            }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <b-tab
        no-body
        lazy
        v-for="(tab, index) in computedTabs"
        :key="tab.id"
        :title-item-class="index >= chunkAt ? 'd-none' : ''"
      >
        <template v-slot:title v-if="index < chunkAt">
          <span class="nav-icon">
            <i
              :class="
                tab.icon
                  ? tab.icon
                  : getTab(tab.actionTypeId, tab.dimensionGroupId, tab).icon
              "
            ></i>
          </span>
          <span class="nav-text" v-html="tab.title" />
        </template>
        <span v-if="tab.title && !tab.description" v-html="tab.title" />
        <br v-if="tab.title && !tab.description" />
        <span v-if="tab.description" v-html="tab.description" />
        <div class="assets mt-8" v-if="tab.assets && tab.assets.length > 0">
          <b-button
            tag="a"
            class="mr-4 mb-4"
            variant="light"
            v-for="asset in tab.assets"
            :key="asset.id"
            :class="'asset-' + asset.type"
            @click="downloadFile(asset)"
          >
            <div
              class="symbol symbol-50 symbol-light mr-5"
              :set="(icon = getAssetIcon(row.item.type))"
            >
              <div class="symbol-label">
                <span v-if="icon.class" class="align-self-center">
                  <i :class="`${icon.class} icon-xl`" />
                </span>
                <span
                  v-else-if="icon.image"
                  class="svg-icon svg-icon-xl align-self-center"
                >
                  <simple-svg
                    :src="require(`@/assets/images/icons/${icon.image}`)"
                    class="h-50 w-50"
                  />
                </span>
                <span
                  v-else-if="icon.file"
                  class="svg-icon svg-icon-lg align-self-center"
                >
                  <img src="@/assets/images/files/ted.svg" class="w-25px" />
                </span>
              </div>
            </div>
          </b-button>
        </div>
        <Youtube
          v-if="Object.keys(youtube).length > 0"
          :code="youtube.content"
        />
        <Ted v-else-if="Object.keys(ted).length > 0" :code="ted.content" />
        <Vimeo
          v-else-if="Object.keys(vimeo).length > 0"
          :code="vimeo.content"
        />
        <Iframe
          v-else-if="Object.keys(iframe).length > 0"
          :code="iframe.content"
        />
        <OwnVideo
          v-else-if="Object.keys(ownVideo).length > 0"
          :code="ownVideo.content"
        />
        <ImageAsset
          v-else-if="Object.keys(image).length > 0"
          :code="image.content"
        />
      </b-tab>
    </b-tabs>
    <Html
      v-if="showHtml"
      :showAsset="showHtml"
      :code="html.content"
      @asset-closed="showHtml = false"
      :name="title"
    />
    <pdf
      v-if="showPdf"
      :base64="showingAsset"
      :showAsset="showPdf"
      :name="pdfName"
      @asset-closed="showPdf = false"
    />
  </b-card>
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";

import { mapGetters } from "vuex";

import Pdf from "@/components/assets/Pdf.component";
import Youtube from "@/components/assets/Youtube.component";
import Ted from "@/components/assets/Ted.component";
import Vimeo from "@/components/assets/Vimeo.component";
import Iframe from "@/components/assets/Iframe.component";
import Html from "@/components/assets/Html.component";
import ImageAsset from "@/components/assets/ImageAsset.component";
import OwnVideo from "@/components/assets/OwnVideo.component";

export default {
  components: {
    Pdf,
    Youtube,
    Ted,
    Vimeo,
    Iframe,
    Html,
    ImageAsset,
    OwnVideo
  },
  data() {
    return {
      tabValue: 0,
      moment: moment,
      showingAsset: "",
      showPdf: false,
      showHtml: false,
      pdfName: "Kymatio.pdf",
      chunkAt: 100,
      youtube: {},
      ted: {},
      vimeo: {},
      ownVideo: {},
      iframe: {},
      html: {},
      zip: {},
      image: {},
      link: {},
      xlsx: {},
      pdf: {},
      excel: {}
    };
  },
  props: {
    title: {
      type: String,
      required: false
    },
    avatar: {
      type: String,
      required: false
    },
    tabs: {
      type: Array,
      required: true
    },
    object: {
      type: Object,
      required: false
    }
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"]),
    invisibleTabs() {
      return _.slice(this.computedTabs, this.chunkAt);
    },
    computedTabs() {
      var result = [];
      _.forEach(this.tabs, action => {
        var obj = {
          id:
            action.actionTypeId.toFixed(0) +
            action.dimensionGroupId.toFixed(0) +
            action.dimensionId.toFixed(0),
          actionTypeId: action.actionTypeId,
          dimensionGroupId: action.dimensionGroupId,
          dimensionId: action.dimensionId,
          title: action.executeAction.title || false,
          description: action.executeAction.description || false,
          assets: action.executeAction.assets || [],
          icon: action.icon || false
        };
        result.push(obj);
      });
      return result;
    }
  },
  methods: {
    getAssetIcon(type) {
      var result = {};
      switch (type) {
        case "youtube_iframe":
          result.class = "socicon-youtube text-danger";
          break;
        case "ted_iframe":
          result.file = "files/ted.svg";
          break;
        case "vimeo_iframe":
          result.class = "socicon-vimeo text-danger";
          break;
        case "iframe":
          result.image = "file.svg";
          break;
        case "audea_link":
          result.image = "warning-circle.svg";
          break;
        case "external_link":
          result.class = "fas fa-link";
          break;
        case "excel":
        case "image":
        case "xlsx":
          result.image = "image-svg";
          break;
        case "zip":
          result.image = "zip.svg";
          break;
        case "html":
          result.image = "attachment.svg";
          break;
        case "own_video":
          result.image = "attachment.svg";
          break;
        default:
          result.class = `fas fa-file-${type}`;
          break;
      }
      return result;
    },
    getTab(actionTypeId, dimensionGroupId, tab) {
      var result = {};
      switch (parseInt(dimensionGroupId)) {
        case parseInt(this.getConstant("DIMENSION_GROUP_INSIDER")):
          if (
            parseInt(actionTypeId) ===
            parseInt(this.getConstant("ACTIONS_TYPE_RECOMMENDATION"))
          ) {
            result.icon = "fas fa-hands-helping";
            result.title = this.$t("training.detail.security.recommendation");
          } else if (
            parseInt(actionTypeId) ===
            parseInt(this.getConstant("ACTIONS_TYPE_PILLS"))
          ) {
            result.icon = "fas fa-capsules";
            result.title = this.$t("training.detail.security.pills");
          } else if (
            parseInt(actionTypeId) ===
            parseInt(this.getConstant("ACTIONS_TYPE_TEXT_WELFARE"))
          ) {
            result.icon = "fas fa-user-shield";
            result.title = tab.title;
          }
          break;
        case parseInt(this.getConstant("DIMENSION_GROUP_GRIPLUS")):
          result.icon = "fas fa-hands-helping";
          result.title = this.$t("training.detail.security.recommendation");
          break;
        case parseInt(this.getConstant("DIMENSION_GROUP_RECOMEND_CLIMATE")):
          result.icon = "fas fa-globe-europe";
          result.title = this.$t("training.detail.wellbeing.recommendation");
          break;
        case parseInt(this.getConstant("DIMENSION_GROUP_CIBERSECURITY")):
          result.icon = "fas fa-shield-alt";
          result.title = tab.title;
          break;
        case parseInt(this.getConstant("DIMENSION_GROUP_SOCIAL_ENGINEERING")):
          result.icon = "fas fa-share-alt";
          result.title = tab.title;
          break;
        case parseInt(this.getConstant("DIMENSION_GROUP_BURNOUT")):
          result.icon = "fas fa-fire";
          result.title = tab.title;
          break;
        default:
          break;
      }
      return result;
    },
    changeTab(index) {
      this.tabValue = index;
    },
    downloadFile(asset, automatic = true) {
      this.ted = {};
      this.youtube = {};
      this.vimeo = {};
      this.iframe = {};
      this.html = {};
      this.own_video = {};
      this.axios
        .get(asset.url)
        .then(response => {
          if (automatic) {
            if (
              response.data.records.forward &&
              asset.type !== "youtube_iframe" &&
              asset.type !== "ted_iframe" &&
              asset.type !== "vimeo_iframe" &&
              asset.type !== "excel" &&
              asset.type !== "image" &&
              asset.type !== "xlsx" &&
              asset.type !== "zip" &&
              asset.type !== "html" &&
              asset.type !== "iframe" &&
              asset.type !== "own_video"
            ) {
              let a = document.createElement("a");
              a.href = response.data.records.content;
              a.target = "_blank";
              a.click();
            } else {
              switch (asset.type) {
                case "pdf":
                  this.showingAsset = response.data.records.content;
                  this.showPdf = true;
                  break;
                case "youtube_iframe":
                  this.youtube = response.data.records;
                  this.showed = true;
                  break;
                case "ted_iframe":
                  this.ted = response.data.records;
                  this.showed = true;
                  break;
                case "vimeo_iframe":
                  this.vimeo = response.data.records;
                  this.showed = true;
                  break;
                case "excel":
                  this.excel = response.data.records;
                  this.showed = true;
                  break;
                case "image":
                  this.image = response.data.records;
                  this.showed = true;
                  break;
                case "xlsx":
                  this.xlsx = response.data.records;
                  this.showed = true;
                  break;
                case "zip":
                  this.zip = response.data.records;
                  this.showed = true;
                  break;
                case "html":
                  this.html = response.data.records;
                  this.showHtml = true;
                  break;
                case "iframe":
                  this.iframe = response.data.records;
                  this.showed = true;
                  break;
                case "own_video":
                  this.ownVideo = response.data.records;
                  this.showed = true;
                  break;
                default:
                  return response.data.records;
              }
            }
          } else {
            return response.data.records.content;
          }
        })
        .catch(error => {
          if (error.response.status === 412) {
            this.$swal(this.$t("generic.errors.fileNotFound"), "", "error");
          }
        });
    }
  }
};
</script>

<style lang="scss">
#user-tabs {
  .nav {
    .nav-item {
      &.dropdown {
        margin-top: 0rem;
      }
      margin-top: 0rem;
      margin-bottom: 5px;
    }
    .nav-link {
      padding: 0.75rem 0.8rem !important;
    }
  }
  .card-title {
    position: relative;
    margin-bottom: 20px;
    min-width: 100%;
    .card-subtitle {
      position: absolute;
      top: 2.6rem;
      font-weight: 500;
      font-size: 0.8rem;
    }
  }
}
.fa-file-html:before {
  content: "\f0c1";
}
</style>

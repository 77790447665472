var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{staticClass:"card card-custom card-stretch",attrs:{"no-body":""}},[_c('div',{staticClass:"card-header border-0"},[_c('h3',{staticClass:"card-title font-weight-bolder text-dark"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('div',{staticClass:"card-body pt-7"},[_c('div',{staticClass:"timeline timeline-5"},[_c('div',{staticClass:"timeline-items"},_vm._l((_vm.formatedSessions),function(session){return _c('div',{key:session.id,staticClass:"timeline-item text-left mb-3"},[_c('div',{class:`timeline-media bg-light-${_vm.getClassBySession(session)}`},[_c('span',{class:`svg-icon svg-icon-2x svg-icon-${_vm.getClassBySession(session)}`},[_c('simple-svg',{attrs:{"src":require(`@/assets/images/icons/fish.svg`)}})],1)]),_c('div',{class:`timeline-desc timeline-desc-light-${_vm.getClassBySession(
                session
              )}`},[_c('span',{staticClass:"font-weight-bolder d-flex align-items-center justify-content-between mb-1"},[_c('span',{class:`text-${_vm.getClassBySession(session)}`,domProps:{"innerHTML":_vm._s(_vm.shortenName(session.name))}}),_c('b-button',{staticClass:"btn-icon mb-2",attrs:{"size":"sm","variant":_vm.selected &&
                  parseInt(_vm.selected) === parseInt(session.surveyEntityId)
                    ? `${_vm.getClassBySession(session)}`
                    : `outline-${_vm.getClassBySession(session)}`},on:{"click":function($event){return _vm.$emit('select', session)}}},[_c('i',{staticClass:"fas fa-chevron-right"})])],1),_c('div',{staticClass:"font-weight-bolder d-flex align-items-top justify-content-between pb-1"},[_c('span',{class:`label label-lg label-outline-${_vm.getClassBySession(
                    session
                  )} font-weight-bolder label-inline`},[_vm._v(" "+_vm._s(session.category)+" ")]),_c('p',{staticClass:"font-weight-normal text-muted text-dark-50",domProps:{"innerHTML":_vm._s(session.calendar)}})])])])}),0)])]),(_vm.hasPager)?_c('div',{staticClass:"card-footer border-top-0 pt-0"},[_c('div',{staticClass:"d-flex justify-content-around"},[_c('div',{staticClass:"d-flex flex-column"},[_c('b-button',{staticClass:"btn-icon",attrs:{"pill":"","variant":_vm.canGoUp ? 'light-primary' : 'disabled',"disabled":!_vm.canGoUp},on:{"click":_vm.up}},[_c('i',{staticClass:"fa fa-chevron-up"})])],1),_c('div',{staticClass:"d-flex flex-column"},[_c('b-button',{staticClass:"btn-icon",attrs:{"pill":"","variant":_vm.canGoDown ? 'light-primary' : 'disabled',"disabled":!_vm.canGoDown},on:{"click":_vm.down}},[_c('i',{staticClass:"fa fa-chevron-down"})])],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-card
    :class="
      'card-custom card-stretch gutter-b ' + (half ? 'card-stretch-half' : '')
    "
    body-class="p-0 d-flex flex-column"
    header-class="border-0"
  >
    <template #header>
      <b-card-title class="font-weight-bolder">
        <div class="card-label">{{ title }}</div>
      </b-card-title>
    </template>
    <div v-if="series[0].data.length > 0" class="p-0 m-0">
      <apexchart
        class="card-rounded-bottom"
        type="area"
        :key="title"
        :ref="title"
        :height="height"
        :options="options"
        :series="series"
      ></apexchart>
    </div>
  </b-card>
  <!--end::Body-->
</template>

<script>
export default {
  components: {},
  name: "Chart",
  props: {
    title: {
      type: String,
      required: true
    },
    series: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    dates: {
      type: Array,
      required: false
    },
    discrete: {
      type: Array,
      required: false
    },
    height: {
      default: "300"
    },
    colors: {
      type: Object,
      required: true
    },
    base: {
      type: Number,
      default: 10
    },
    opacity: {
      type: Boolean,
      default: true
    },
    half: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fill() {
      if (this.opacity) {
        return {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: true,
            opacityFrom: 0.7,
            opacityTo: 0.3,
            stops: [0, 100, 100, 100]
          }
        };
      } else {
        return {
          type: "solid",
          opacity: 0.5
        };
      }
    },
    options() {
      var self = this;
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {},
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        fill: this.fill,
        stroke: {
          curve: "smooth",
          show: true,
          width: 4,
          colors: this.colors.light
        },
        xaxis: {
          categories: this.categories,
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false,
            style: {
              colors: "#B5B5C3",
              fontSize: "12px",
              fontFamily: "Raleway"
            }
          },
          crosshairs: {
            show: false,
            position: "front",
            stroke: {
              color: "#E5EAEE",
              width: 1,
              dashArray: 3
            }
          },
          tooltip: {
            enabled: self.dates && self.dates.length > 0,
            formatter: function(val, index) {
              return self.dates && self.dates[index.dataPointIndex]
                ? self.dates[index.dataPointIndex]
                : null;
            },
            offsetY: 0,
            style: {
              fontSize: "12px",
              fontFamily: "Raleway"
            }
          }
        },
        yaxis: {
          min: 0,
          max: this.base + 1,
          labels: {
            show: false,
            style: {
              colors: this.colors.light,
              fontSize: "12px",
              fontFamily: "Raleway"
            }
          }
        },
        states: {
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0
            }
          }
        },
        tooltip: {
          style: {
            fontSize: "12px",
            fontFamily: "Raleway"
          },
          y: {
            formatter: function(val) {
              return val !== 0.0001
                ? self.$n(val.toFixed(1)) + " / " + self.base
                : "";
            }
          },
          x: {
            show: true
          }
        },
        colors: this.colors.light,
        grid: {
          borderColor: "#ECF0F3",
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true
            }
          },
          padding: {
            right: 0,
            left: 0
          }
        },
        markers: {
          colors: this.colors.light,
          strokeColor: this.colors.light,
          strokeWidth: 4,
          size: 5,
          discrete: self.discrete || []
        }
      };
    }
  }
};
</script>
